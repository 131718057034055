import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'new-footer',
    templateUrl: './new-footer.html',
    styleUrls: ['./new-footer.css']
})

export class NewFooter implements OnInit {
    
    constructor() {
        
    }
    ngOnInit(): void {
    }
}