export class Profile {
    name: string;
    mobile: string;
    email: string;
    relation: string;
    gender: string;
    dob: string;
    height: string;
    weight: string;
    martialStatus: string;
    annualIncome: string;
    religion: string;
    caste: string;
    education: string;
    college: string;
    sisters;
    brothers;
    hello;
    personalizedDetails;
    careerDetails;
    horoscopeDetails;
    familyDetails;
    manglik: string;
    locality: string;
    disabledPart: string;
    lat_locality: string;
    long_locality: string;
    qualification: string;
    occupation: string;
    designation: string;
    workingCity: string;
    about: string;
    abroad: string;
    birthPlace: string;
    birthTime: string;
    gotra: string;
    foodChoice: string;
    fatherStatus: string;
    motherStatus: string;
    familyIncome: string;
    verifiedAt;
    managedBy;
    familyType;
    houseType;
    photo: string;
    image1: string;
    image2: string;
    image3: string;
    photoScore = 0;
    contact;
    reject;
    shortlist;
    myprofile;
    personal;
    horoscope;
    family;
    preference;
    logout;
    subscription;
    aboutMe;
    aboutFamily;
    myself;
    age;
    educational;
    additional;
    company;
    birthDate;
    whatsapp;
    familyLivingIn;
    ageMin;
    ageMax;
    heightMin;
    heightMax;
    incomeMin;
    incomeMax;
    castePref;
    manglikPref;
    motherTonguePref;
    foodChoicePref;
    occupationPref;
    workingPref;
    desc;
    credits;
    todaysSpecial;
    discover;
    likesYou;
    yourLikes;
    history;
    identity_number;
    temple_id;
}
