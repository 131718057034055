import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'custom-bg',
  templateUrl: './custom-bg.html',
  styleUrls: ['./custom-bg.css']
})
export class CustomBg implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
