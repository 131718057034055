import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css']
})
export class PaymentPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
